<po-page-default > 
    
    <po-loading-overlay *ngIf="carregando"
        p-screen-lock="true"
        p-text="Carregando" >
    </po-loading-overlay>

    <div class="center top">
        <po-widget
            p-no-border="true">            
            <div class="po-xl-3 po-lg-3 po-md-3 po-sm-12">
            <!-- 
                <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                    <div class="po-font-text-bold">
                        Detalhes do Ticket
                    </div>
                </div> 
            -->

                <po-divider 
                    p-label="Detalhes do Ticket"
                    class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                </po-divider>

                <div class="po-xl-6 po-lg-6 po-md-6 po-sm-6">
                    <div class="po-font-text">
                        Ticket
                    </div>
                </div>

                <div class="po-xl-6 po-lg-6 po-md-6 po-sm-6">
                    <div class="po-font-text-bold">
                        {{detalheticket.ticket}}
                    </div>
                </div>

                <div class="po-xl-6 po-lg-6 po-md-6 po-sm-6">
                    <div class="po-font-text">
                        Criado em
                    </div>
                </div>
                <div class="po-xl-6 po-lg-6 po-md-6 po-sm-6">
                    <div class="po-font-text-bold">
                        {{detalheticket.inicio}}
                    </div>
                </div>
                    
                <div class="po-xl-6 po-lg-6 po-md-6 po-sm-6">
                    <div class="po-font-text">
                        Status
                    </div>
                </div>

                <div class="po-xl-6 po-lg-6 po-md-6 po-sm-6">
                    <po-tag 
                        [p-value]="detalheticket.status"
                        [p-color]="corstatus">
                    </po-tag>
                </div>

                <!-- <po-multiselect (p-change)="atualizarContatos($event)" p-auto-focus="true" p-required="false" class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12"
                    name="contatossel" p-label="Contatos" [p-options]="contatos" [(ngModel)]="contatossel">
                </po-multiselect> -->


                <div [hidden]="detalheticket.status === 'Finalizado'">
                    
                    <!-- <po-divider 
                        p-label="Validação do Ticket"
                        class="po-mt-5 po-xl-12 po-lg-12 po-md-12 po-sm-12">
                    </po-divider>                 -->

                    <div class="po-xl-3 po-lg-3 po-md-3 po-sm-3">
                        <!-- <po-button                                 
                            p-icon="po-icon po-icon-ok"
                            p-type="link"
                            p-small="true"
                            p-label="Finalizar Ticket" 
                            (p-click)="contirmafinalizarTicket()"> 
                        </po-button> -->
                    
                    </div>                
                </div>

                <!-- <po-divider 
                    p-label="Anexos do Ticket"
                    class="po-mt-5 po-xl-12 po-lg-12 po-md-12 po-sm-12">
                </po-divider> -->
    
                <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12" *ngFor ="let arquivo of detalheticket.anexos">
                        
                    <div class="po-font-text-smaller po-xl-12 po-lg-12 po-md-12 po-sm-12 po-mt-1">
                        <a href="{{url + detalheticket.pathanexos + '/' + arquivo}}" target="_blank">
                            {{arquivo}} 
                        </a>
                    </div>           

                </div>
                <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                    <!-- <po-button  
                        p-icon="po-icon po-icon-attach"
                        p-type="link"
                        p-small="true"
                        p-label="Novo Anexo" 
                        (p-click)="novoAnexo()"> 
                    </po-button> -->
                </div>

            </div>

            <div class="po-xl-9 po-lg-9 po-md-9 po-sm-12">
                <div class="po-ml-5">
                    <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12  po-font-subtitle">
                        {{detalheticket.assunto}}
                    </div>
                    
                    <po-container
                        p-height="400"                             
                        p-no-border="true"
                        p-no-padding="true"
                        p-no-shadow="true"
                        class="po-ml-1 po-font-text po-xl-12 po-lg-12 po-md-12 po-sm-12">
                        <div [innerHtml]="detalheticket.descricao">
                        </div>
                    </po-container>

                    <po-divider class="po-xl-12 po-lg-12 po-md-12 po-sm-12" >
                    </po-divider>
                    <div [hidden]="detalheticket.status === 'Finalizado'">

                        <!-- <po-rich-text class="po-xl-12 po-lg-12 po-md-12 po-sm-12"  
                            p-clean p-placeholder="Inserir Comentário" 
                            name="comentario" 
                            [(ngModel)]="comentario" 
                            p-height="170"> 
                        </po-rich-text>                              
                    
                        <div class="salvar po-xl-3 po-lg-3 po-md-3 po-sm-3">
                            <po-button 
                                [p-disabled]="comentario"
                                p-icon="po-icon po-icon-sms"
                                p-type="primary"
                                p-small="true"
                                p-label="Salvar Comentário" 
                                (p-click)="salvarSequencia()"> 
                            </po-button>
                        </div> -->

                    </div>    
                    <po-container *ngFor ="let seq of sequencias; let i = index"
                        p-no-border="true"    
                        [class]="i % 2 === 0 ? 'background_par po-xl-12 po-lg-12 po-md-12 po-sm-12' : 'po-xl-12 po-lg-12 po-md-12 po-sm-12' "> 
                                                
                        <div class="po-xl-2 po-lg-2 po-md-2 po-sm-2">

                            <po-avatar 
                                p-size="sm"
                                p-src="{{seq.suporte ? 'assets/imgs/suporte.png' : 'assets/imgs/user.png'}}">                        
                            </po-avatar>                        

                            <div class="po-font-text-large-bold ">
                                {{seq.usuario}}
                            </div>
                                
                            <div class="po-font-text-smaller ">
                                {{seq.data + ' - ' + seq.hora  }}
                            </div>                        

                        </div>

                        <div [innerHtml]="seq.descricao" class="po-xl-9 po-lg-9 po-md-9 po-sm-9" >
                        </div>

                    </po-container>
            
                </div>

            </div>

        </po-widget>
    </div>

    
</po-page-default>

<po-modal #poModalAnexo
    p-title="{{'Anexos do Ticket | ' + ticket}}" 
    p-hide-close="true">
                
    <app-anexos 
        [chave]="detalheticket.ticket" 
        [arquivos]="detalheticket.anexos" 
        diretorio="tickets" 
        [pathanexos]="detalheticket.pathanexos" 
        [anexoativo]="true">
    </app-anexos>                

</po-modal>

<po-modal 
    #poModalFinaliza 
    p-title="Finalizar Ticket" 
    [p-primary-action]="confirm" 
    [p-secondary-action]="close" 
    p-hide-close="false">
    
    <div class="po-font-text-large" >
        Confirma a finalização do Ticket? 
    </div>

</po-modal>


