import { Router } from '@angular/router';
import { async } from '@angular/core/testing';
import { HttpService } from './../services/http.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PoModalComponent, PoMultiselectOption, PoNotificationService, PoTableAction, PoTableColumn } from '@po-ui/ng-components';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('poModalAnexos') poModalAnexos: PoModalComponent;

  ticketsabertos = 0;
  ticketscomcliente = 0;
  ticketscomsuporte = 0;
  ticketatual;
  listaanexos = [];
  pathanexos;
  carregando;

  mostrarBtnCarregar: boolean;
  paginaAtual: number;

  colunas: Array<PoTableColumn> =
    [
      {
        property: 'status',
        type: 'subtitle',
        width: '10%',
        subtitles: [
          { value: 'Novo', color: 'color-10', label: 'Novo', content: '' },
          { value: 'Em Atendimento', color: 'color-02', label: 'Em Atendimento', content: '' },
          { value: 'Aguardando Cliente', color: 'color-07', label: 'Aguardando Cliente', content: '' },
          { value: 'Finalizado', color: 'color-08', label: 'Finalizado', content: '' },
      ]
      },
      { property: 'ticket', label: 'Ticket', width: '70px' },
      { property: 'assunto', label: 'Assunto', width: '200px' },
      { property: 'inicio', label: 'Início', width: '100px'},      
      { property: 'usuario', label: 'Usuário', width: '100px'},
      {
        property: 'classificacao',
        type: 'label',
        width: '10%',
        labels: [
          { value: 'Urgente'    , color: 'color-07', label: 'Urgente'},
          { value: 'Alto'       , color: 'color-08', label: 'Alto'},
          { value: 'Normal'     , color: 'color-02', label: 'Normal'},
          { value: 'Baixo'      , color: 'color-01', label: 'Baixo'},        
        ]        
      },      
      { property: 'atualizado', label: 'Atualizado', width: '100px' },
      {
        property: 'acoes',
        label: 'Ações',
        type: 'icon', 
        icons: [
          {
            action: this.detalhe.bind(this),
            icon: 'po-icon po-icon-list',
            tooltip: 'Detalhes do Ticket',
            value: 'detalhes',
          },
          {
            action: this.anexos.bind(this),
            icon: 'po-icon po-icon-attach',
            tooltip: 'Anexos do Ticket',
            value: 'anexos',
          }
        ]}
    ];

  itens = [];
  statussel = [];
  ticket = '';

  status: Array<PoMultiselectOption> = [
    { value: '1', label: 'Aberto' },
    { value: '2', label: 'Em Atendimento' },
    { value: '3', label: 'Aguardando Cliente' },
    { value: '4', label: 'Encerrado' }
  ];

  constructor(
      private http: HttpService, 
      private router: Router, 
      private notificacao: PoNotificationService
      ) { }

  async ngOnInit(): Promise<void> {
    
    let sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));

    if (sessao.panel) {
      this.router.navigate(['/painel-redmine'])
    }
    this.mostrarBtnCarregar = true;
    this.paginaAtual = 1
    this.carregando = true;
    await this.getTickets();
    await this.getIndicadores();
    // await this.getRedmines();
    this.carregando = false;
  }

  anexos(linha): void {
    this.ticketatual  = linha.ticket;
    this.listaanexos = linha.anexos;
    this.pathanexos = linha.pathanexos;
    this.poModalAnexos.open();
  }

  detalhe(linha): void {
    console.log(linha);
    this.router.navigate(['/consulta', linha.ticket]);    
  }

  async getTickets(pagina?: number)  {

    if(pagina === undefined)
      this.paginaAtual = pagina = 1

    return new Promise((resolve, reject) => {
      this.http.get('/suporte/tickets?status=' + this.statussel + '&ticket=' + this.ticket + "&page=" + pagina.toString() +"&pagesize=10").subscribe(
        data => {
          const ret: any = data;

          // TO-DO levar logica para parte do http
          if (ret.code === '401') {
            this.notificacao.error(ret.message);
            localStorage.clear();
            sessionStorage.clear()
            this.router.navigate(['/login'])
            resolve(true);
          }

          this.itens = ret.items;
          
          if (ret.proximo !== undefined) 
            this.mostrarBtnCarregar = ret.proximo;

          resolve(true);
        },        
        error => {
          reject();
        }
      );
    })  
  };
  
  async getIndicadores() {
    
    return new Promise((resolve, reject) => {
    this.http.get('/suporte/indicadores?').subscribe(
      data => {
        const ret: any = data;
        if (ret.code == '401') {          
          this.notificacao.error(ret.message);
        } else {

          if (ret.indicadores.total) {
            this.ticketsabertos = ret.indicadores.total;
          }
          if (ret.indicadores.comcliente) {
            this.ticketscomcliente = ret.indicadores.comcliente;
          }
          if (ret.indicadores.comsuporte) {
            this.ticketscomsuporte = ret.indicadores.comsuporte;
          }
        }
        resolve(true);
      },
      error => {
        reject();
      });
    });
 } 

  /**
    * Recupera mais dados dos tickets.
  */
  async CarregarMais(): Promise<void>{
    let tmpTicket = this.itens
    
    this.paginaAtual++
    this.carregando = true;
    
    await this.getTickets(this.paginaAtual)
      .then(() => {
        if( this.itens.length != 0){
          
          this.itens.forEach(element => {
            tmpTicket.push(element);
          });
          this.itens = tmpTicket;
        
        }
        this.carregando = false;
      });
  }
}
