import { Component, OnInit, ViewChild } from '@angular/core';
import { PoLanguage, PoNotificationService } from '@po-ui/ng-components';
import { PoPageLoginLiterals, PoModalPasswordRecoveryComponent,PoModalPasswordRecoveryType, PoModalPasswordRecovery} from '@po-ui/ng-templates';
import { environment } from './../../environments/environment'
import {  } from '@po-ui/ng-templates';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
 
  email: string;
  event: string;
  submitEvent: string;
  type: PoModalPasswordRecoveryType;
  linguagem: PoLanguage = {language: 'pt'};
  literals: PoPageLoginLiterals = {
    rememberUser: 'Lembrar usuário',
    registerUrl: 'Não tem login? Cadastre-se',
    loginHint: 'Insira o email utilizado no cadastro, caso não possua, cadastre-se agora.',
    rememberUserHint: 'Esta opção mantém a conexão ativa durante a sessão.',
    welcome: 'Olá, bem vindo!',
  };
  @ViewChild(PoModalPasswordRecoveryComponent) poModalPasswordRecovery: PoModalPasswordRecoveryComponent;
  constructor( private http: HttpClient, private poNotificacao: PoNotificationService ) { }
  
  ngOnInit(): void {
    this.email = "suporte@userfunction.com";
    this.event = undefined;
    this.submitEvent = undefined;
    this.type = PoModalPasswordRecoveryType.Email;
  }

  changeEvent(event: string) {
    this.event = event;
  }

  openPasswordRecoveryModal() {
    this.poModalPasswordRecovery.open();
  }

  submit(event: PoModalPasswordRecovery) {

    this.http.post( environment.urlApi + "/suporte/solicitartrocasenha", event
    ).subscribe(
      data  => {
        const response: any = data;
        if ( response.code === "201") {
          this.openConfirmation(event);
        }
      },
      error => {
        const responseError: any = error;        
        this.poNotificacao.error( responseError.error.errorMessage);
      }
    );
  }

  private openConfirmation(event: PoModalPasswordRecovery) {
    this.submitEvent = JSON.stringify(event);
    this.poModalPasswordRecovery.openConfirmation();
  }
}
