import { environment } from './../../environments/environment.prod';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PoNotificationService } from '@po-ui/ng-components';

@Component({
  selector: 'app-novo-usuario',
  templateUrl: './novo-usuario.component.html',
  styleUrls: ['./novo-usuario.component.css']
})
export class NovoUsuarioComponent implements OnInit {

  @ViewChild('passwordForm', { read: NgForm, static: true }) passwordForm: NgForm;

  password;
  confirmPassword;
  login;
  cnpj;
  nome;
  carregando;
  
  mostrarMenuEmitter = new EventEmitter<boolean>();
  
  constructor(
              private poNotificacao: PoNotificationService,
              private http: HttpClient,
              private rota: Router
              ) { }


  ngOnInit(): void {
      this.mostrarMenuEmitter.emit(false);
  }

  onLoginSubmit(): void {

    if (this.confirmPassword === this.password) {
      this.carregando = true;

      this.http.post(environment.urlApi + '/suporte/cadastro', this.passwordForm.value).subscribe(
          data => {
            const res: any = data;

            if (res.code === '400') {
              this.poNotificacao.error(res.detail);
            } else if (res.code === '201') {
              this.poNotificacao.success('Usuário criado com sucesso.');
              this.rota.navigate(['/']);
            }

            this.carregando = false;

          },
          error => {
            this.carregando = false;
            this.poNotificacao.error('Erro ao tentar criar usuário.');
          },
        );
    } else {
      this.poNotificacao.error('O campo senha e confirme sua senha não estão iguais.')
    }

}

}
