<po-page-default > 
    
    <po-loading-overlay *ngIf="carregando"
        p-screen-lock="true"
        p-text="Carregando" >
    </po-loading-overlay>
    
    <div class="center top">

        <div >
            <p class="po-font-text-large-bold" style="width: 50%; margin-left: 22px;">
                ATENÇÃO,
                    este portal está ativo apenas para CONSULTAS de histórico de chamados.
                    Para acessar o novo portal de chamados da Inforshop, <a href="https://ufunction.freshdesk.com/support/login">CLIQUE AQUI - NOVO PORTAL INFORSHOP</a>                    
            </p>
            <br><br>
        </div>
        
        <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
            <po-widget class="po-xl-4 po-lg-4 po-md-12 po-sm-12">                
                <img alt="logo" class="imagens" src="assets/imgs/ticket.png">        
                <div class="kpi po-font-title">{{ticketsabertos}}</div>
                <div class="titulo po-font-text-large-bold">Tickets Abertos</div>
            </po-widget>
            <po-widget class="po-xl-4 po-lg-4 po-md-12 po-sm-12">                
                <img alt="logo" class="imagens" src="assets/imgs/user.png">        
                <div class="kpi po-font-title">{{ticketscomcliente}}</div>
                <div class="titulo po-font-text-large-bold">Aguardando Cliente</div>
            </po-widget>
            <po-widget class="po-xl-4 po-lg-4 po-md-12 po-sm-12">                
                <img alt="logo" class="imagens" src="assets/imgs/suporte.png">        
                <div class="kpi po-font-title">{{ticketscomsuporte}}</div>
                <div class="titulo po-font-text-large-bold">Aguardando Suporte</div>
            </po-widget>
        </div>
        <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
            <po-input p-auto-focus (keyup.enter)="getTickets()" 
            (p-change)="getTickets()" [(ngModel)]="ticket" 
            class="po-xl-9 po-lg-9 po-md-9 po-sm-12" name="buscar" p-placeholder="Pesquisar Tickets"> </po-input>
            <po-multiselect 
                (p-change)="getTickets()"
                [(ngModel)]="statussel"
                class="po-xl-3 po-lg-3 po-md-3 po-sm-12" 
                name="statussel" 
                p-placeholder="Status" 
                [p-options]="status"> 
            </po-multiselect>
            <po-widget class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                <po-table                    
                    [p-columns]="colunas"
                    [p-items]="itens"
                ></po-table>
                
            </po-widget>
            <div  
                *ngIf="itens.length > 9 && mostrarBtnCarregar " 
                class="po-offset-lg-5  ppo-xl-12 po-lg-12 po-md-12 po-sm-12"
            >
                <po-button 
                    p-label="Carregar mais resultados" 
                    (p-click)="CarregarMais()"
                > 
                </po-button>                            
            </div>
        </div>
        
    </div>

</po-page-default>

<po-modal #poModalAnexos p-title="Anexos" p-hide-close="false">
    <app-anexos [chave]="ticketatual" [arquivos]="listaanexos" diretorio="tickets" [pathanexos]="pathanexos" [anexoativo]="true"></app-anexos>
</po-modal>


