
<po-modal-password-recovery
    [p-contact-email]="email"
    [p-type]="type"
    (p-submit)="submit($event); changeEvent('p-submit')"
    #testeRecovery>
</po-modal-password-recovery>

<po-page-login class="login"    
    p-product-name="Portal de Suporte"
    [p-literals]="literals"    
    [p-languages]="linguagem"
    p-authentication-type="Bearer"
    p-register-url="/novousuario"
    [p-recovery]="this.openPasswordRecoveryModal.bind(this)">
</po-page-login>