import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PoMenuItem, PoToolbarAction, PoToolbarProfile } from '@po-ui/ng-components';
import { AuthGuard } from './services/auth.guard';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  mostrarmenu = false;
  usuariologado: string;

  items = [
    {label: 'Meus Tickets'    , link: '/' },
    // {label: 'Novo Ticket'     , link: '/ticket', disabled: true }
  ];

  logo = 'assets/imgs/logo-menu.png';

  icones = [{  action: this.sair.bind(this),
              label: 'Sair',
              icon: 'po-icon po-icon-exit'}
           ];

  constructor(private authguard: AuthGuard, private route: Router) {}

  ngOnInit(): void {
    this.authguard.mostrarMenuEmitter.subscribe(
      (mostrar: boolean) => {
         this.mostrarmenu = mostrar;
      }
    );
    this.authguard.usuarioEmitter.subscribe(
      (sessao: any) => {
        this.usuariologado = sessao.nameuser;        
      }
    );
  }

  sair(): void {
    sessionStorage.clear();
    localStorage.clear();
    this.mostrarmenu = false;
    this.route.navigate(['/login']);
  }

}
