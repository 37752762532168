<po-loading-overlay *ngIf="carregando"
    p-screen-lock="true"
    p-text="Processando" >
</po-loading-overlay>

<po-page-change-password
  p-hide-current-password
  p-logo="./../../assets/imgs/uf-logo.png"
  p-url-home="/login"
  [p-requirements]="requirements"
  (p-submit)="onSubmit()"
>
</po-page-change-password>
