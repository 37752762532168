import { Component, OnInit, Input } from '@angular/core';
import { PoUploadLiterals } from '@po-ui/ng-components';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-anexos',
  templateUrl: './anexos.component.html',
  styleUrls: ['./anexos.component.css']
})
export class AnexosComponent implements OnInit {

  @Input() chave: string;
  @Input() diretorio: string;
  @Input() pathanexos: string;
  @Input() arquivos: string[];
  @Input() anexoativo: boolean;

  constructor(
    private httpService: HttpService,
  ) { }

  customLiterals: PoUploadLiterals = {
    selectFile: 'Anexar Arquivos',
    startSending: 'Enviar'
   };

  url = environment.urlHttp;
  urlupload = environment.urlApi + '/nyxupload';

 
  ngOnInit(): void {
  }

  antesdeupar(event) {
    console.log(event);
    const file = event.file.rawFile;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        const arquivo = event.file.name;
        const body = {
          base64: reader.result, extension: event.file.extension, name: arquivo, diretorio: this.diretorio, chave: this.chave};
        this.httpService.post('/nyxupload/', body).subscribe(
          data => {
            // console.log(data);
            this.arquivos.push(data['arquivo']);
          }
        );
    };
  }

  excluirarquivo(arquivo): void {
    const body = {
      base64: '', extension: '', name: arquivo, diretorio: this.diretorio, chave: this.chave};
    this.httpService.post('/nyxupload?excluir=sim', body).subscribe(
      data => {
        this.arquivos.splice(this.arquivos.indexOf(arquivo), 1);
      }
    );
  }

}
