import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  @ViewChild('iframe') iframe: ElementRef
  
  constructor() { }

  tabgd;
  frame;
  
  ngOnInit(): void {

    let sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));

    if (sessao.tabgd) {
      this.tabgd = 'https://analytics.totvs.com.br/dashboard.html#project=/gdc/projects/kig8vaxc6i0q9962i1yffyuvmj63yfzx&dashboard=/gdc/md/kig8vaxc6i0q9962i1yffyuvmj63yfzx/obj/609&tab='+sessao.tabgd+'&nochrome=true'
    }

  }

  ngAfterViewInit() {
    if (this.tabgd) {
      this.iframe.nativeElement.setAttribute('src', this.tabgd);
    }
    
  }

}
