<po-page-default > 
        
    <div class="center top">
        <form #passwordForm="ngForm">
            <div class="po-font-title po-xl-12 po-lg-12 po-md-12 po-sm-12">
                Novo Ticket
            </div>
            <po-divider class="po-xl-12 po-lg-12 po-md-12 po-sm-12"></po-divider>
            <po-input class="po-md-9 po-sm-mb-9 po-mb-9 po-lg-mb-9"                   
                p-auto-focus="true"     
                p-placeholder="Informe o Assunto"
                name="assunto" 
                [(ngModel)]="assunto"                
                p-required="true"
                p-label="Assunto"> 
            </po-input>
            <po-select
                p-auto-focus="false" 
                p-required="true" 
                class="po-md-3 po-sm-mb-3 po-mb-3 po-lg-mb-3" 
                name="modulo" 
                p-label="Módulo" 
                p-placeholder="Módulo"
                [p-options]="modulos" 
                [(ngModel)]="modulo">  
            </po-select>
            
            <po-multiselect p-auto-focus="true" p-required="false" class="po-md-12 po-sm-mb-12 po-mb-12 po-lg-mb-12"
                name="contatossel" p-label="Contatos Adicionais" [p-options]="contatos" [(ngModel)]="contatossel">
            </po-multiselect>

            
            <po-rich-text class="po-xl-12 po-lg-12 po-md-12 po-sm-12"  
            class="po-xl-12 po-lg-12 po-md-12 po-sm-12"  
                p-required="true"     
                p-clean 
                p-label="Descrição"
                p-placeholder="Preencha a descrição do problema" 
                name="descricao" 
                [(ngModel)]="descricao" 
                p-height="170"
                >
            </po-rich-text>           
            
        </form>
        
        <po-upload 
            #upload
            name="project"
            [(ngModel)]="project"
            [p-url]="urlupload" 
            (p-upload)="antesdeupar($event)" 
            p-multiple="true"
            p-hide-select-button
            p-hide-send-button
            name="upload" 
            p-label="Anexar arquivos ao ticket"
            p-drag-drop-height="160"
            p-drag-drop="true" 
            class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
        </po-upload>

        <po-divider class="po-xl-12 po-lg-12 po-md-12 po-sm-12"></po-divider>
        <po-button  class="po-md-12"               
            [p-loading]="carregando"    
            p-type="primary"
            [p-disabled]="passwordForm.invalid"
            (p-click)="salvarTicket()"
            p-label="Salvar Ticket">
        </po-button>


    </div>


</po-page-default>



