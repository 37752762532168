import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-atualizar',
  templateUrl: './atualizar.component.html',
  styleUrls: ['./atualizar.component.css']
})
export class AtualizarComponent implements OnInit {

  constructor(private http: HttpService,
              private rota: Router
    ) { }

  carregando;

  ngOnInit(): void {
    this.carregando = true;
    this.http.get('/redmines/tickets?atualizar=S').subscribe(
      data => {
        this.rota.navigate(['/painel-redmine'])    
      }
    )
  }

}
