<po-page-default>

    <form #passwordForm="ngForm">
          <div class="po-md-12" >

            <div class="center po-font-display po-xl-12 po-lg-12 po-md-12 po-sm-12">
              Portal de Suporte
            </div>

            <div class="center po-font-text-large po-ml-12">
                Informe seus dados e entre em contato agora com nosso suporte.  
            </div>

            <po-divider class="po-md-12">

            </po-divider>

            <po-input                    
              p-placeholder="Informe seu nome"
              name="nome" 
              [(ngModel)]="nome"                
              p-required="true"
              p-icon="po-icon po-icon-user"                 
              p-label="Seu nome"> 
            </po-input>
            
             <po-email 
                p-placeholder="Email - Utilize seu email coorporativo"
                name="login" 
                [(ngModel)]="login"
                p-label="Email"> 
              </po-email>

              <po-password
                p-minlength="6"
                p-placeholder="Informe usa senha"
                name="password"
                [(ngModel)]="password"
                p-required
                p-label="Senha"
                p-error-pattern="Senha inválida"
              >
              </po-password>
                        
              <po-password
                p-minlength="6"
                p-placeholder="Confirme sua senha"
                name="confirmPassword"
                [(ngModel)]="confirmPassword"
                p-required
                p-label="Confirme sua senha"
                p-error-pattern="Senha inválida"
              >
              </po-password>

              <!-- <po-input                    
                p-no-autocomplete="true"            
                p-placeholder="CNPJ"
                p-minlength="11"                
                p-mask="99999999999999"
                name="cnpj" 
                [(ngModel)]="cnpj"                
                p-required="true"
                p-icon="po-icon po-icon-user"                 
                p-label="CNPJ"> 
              </po-input> -->
   
        </div>
        <br><br>
        <po-button  class="po-md-12"               
            [p-loading]="carregando"    
            p-type="primary"
            [p-disabled]="passwordForm.invalid"
            p-label="Cadastrar"
            (p-click)="onLoginSubmit()">
        </po-button>

        <po-divider class="po-md-12">

        </po-divider>
    
        <div class="center">
          <div class="po-font-text po-md-12">
              <span class="po-icon po-icon-help"></span>            
              Dúvidas? Fale conosco pelo email: <a href="mailto:suporte@userfunction.com.br">suporte@userfunction.com.br</a>
          </div>
        </div>
        

    </form>



</po-page-default>

