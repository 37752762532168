import { Component, OnInit, ViewChild } from '@angular/core';
import { PoPageChangePasswordComponent, PoPageChangePasswordRequirement } from '@po-ui/ng-templates';
import { environment } from './../../environments/environment.prod'
import { HttpClient } from '@angular/common/http';
import { PoNotificationService } from '@po-ui/ng-components';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-redefinir-senha',
  templateUrl: './redefinir-senha.component.html',
  styleUrls: ['./redefinir-senha.component.css']
})
export class RedefinirSenhaComponent implements OnInit {

  token: string;
  private sub: any;
  carregando: boolean;
  requirements: Array<PoPageChangePasswordRequirement> = [
    { requirement: 'Use pelo menos um símbolo (ex. !, @, #).', status: this.validateSymbols.bind(this) },
    { requirement: 'Misture caracteres maiúsculos e minúsculos.', status: this.validateCases.bind(this) },
    { requirement: 'Mínimo de 5 caracteres.', status: this.validateCharacters.bind(this) }
  ];

  @ViewChild(PoPageChangePasswordComponent, { static: true }) changePassword: PoPageChangePasswordComponent;
  
  constructor(
    private http: HttpClient,
    private poNotificacao: PoNotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  onSubmit() {
    this.http.post( environment.urlApi + "/suporte/realizartrocasenha", 
    {
      token: this.token,
      senha: this.changePassword.newPassword
    }).subscribe(
      data => {
        const response: any = data;
        if( response.code === '400' ){
          this.poNotificacao.error( response.message );
        } else if(response.code === '200') {
          this.poNotificacao.success( response.message );
          this.router.navigate(['/']);
        }  
      },
      error => {
        this.messageError(error);
      }
    );
  }
  
  validateCases(newPassword: string) {
    if (newPassword) {
      let result = newPassword.match(/[a-z]/g);

      if (!result || result.length < 1) {
        return false;
      }

      result = newPassword.match(/[A-Z]/g);

      if (!result || result.length < 1) {
        return false;
      }
      return true;
    }
  }

  validateCharacters(newPassword: string) {
    return newPassword && newPassword.length >= 5;
  }

  validateSymbols(newPassword: string) {
    if (newPassword) {
      const result = newPassword.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/g);

      if (!result || result.length < 1) {
        return false;
      }
      return true;
    }
  }

  async validarToken(){
    return new Promise((resolve, reject) => {
      this.sub = this.activatedRoute.queryParamMap.subscribe( params => {
        this.token = params.get('token');
        this.http.post( environment.urlApi + "/suporte/realizartrocasenha", 
        {
          token: this.token
        }).subscribe(
          data => {
            const response: any = data;
            if( response.code === '200' ){
              //this.poNotificacao.success( response.message );
              resolve(true);
            } 
          },
          error => {
            this.messageError(error);
            reject();
          });          
      });
    });
  }
  
  async ngOnInit(): Promise<void> {
    this.carregando = true;
    await this.validarToken();
    this.carregando = false;
  }

  private messageError( error: any):void{
    const responseError: any = error;
    this.poNotificacao.error( responseError.error.errorMessage);
    this.router.navigate(['/']);
  }
}
