import { PainelRedmineComponent } from './painel-redmine/painel-redmine.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PoModule } from '@po-ui/ng-components';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PoPageLoginModule} from '@po-ui/ng-templates';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './services/auth.guard';
import { NovoUsuarioComponent } from './novo-usuario/novo-usuario.component';
import { FormsModule } from '@angular/forms';
import { PoNavbarModule } from '@po-ui/ng-components';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NovoTicketComponent } from './novo-ticket/novo-ticket.component';
import { AnexosComponent } from './share/anexos/anexos.component';
import { DetalheTicketComponent } from './detalhe-ticket/detalhe-ticket.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { DragDropModule  } from '@angular/cdk/drag-drop';
import { AtualizarComponent } from './atualizar/atualizar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RedefinirSenhaComponent } from './redefinir-senha/redefinir-senha.component';
import { PoModalPasswordRecoveryModule } from '@po-ui/ng-templates';
import { PoPageChangePasswordModule } from '@po-ui/ng-templates';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NovoUsuarioComponent,
    NovoTicketComponent,
    AnexosComponent,
    DetalheTicketComponent,
    PainelRedmineComponent,
    AtualizarComponent,
    DashboardComponent,
    RedefinirSenhaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PoModule,
    PoPageLoginModule,
    FormsModule,
    RouterModule,
    PoNavbarModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([]),
    DragDropModule,
    PoModalPasswordRecoveryModule,
    PoPageChangePasswordModule
  ],
  providers: [AuthGuard, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
