import { Router } from '@angular/router';
import { PoMultiselectOption, PoNotificationService, PoUploadComponent } from '@po-ui/ng-components';
import { HttpService } from './../services/http.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-novo-ticket',
  templateUrl: './novo-ticket.component.html',
  styleUrls: ['./novo-ticket.component.css']
})
export class NovoTicketComponent implements OnInit {

  @ViewChild('passwordForm', { read: NgForm, static: true }) passwordForm: NgForm;
  @ViewChild('upload', { static: true }) upload: PoUploadComponent;
  
  assunto;
  descricao;
  carregando;

  chave: string;
  arquivos: string[];
  anexoativo: boolean;

  url = environment.urlHttp;
  urlupload = environment.urlApi + '/nyxupload';
  
  contatos = []
  contatossel;
  
  project: Array<any> = [];

  modulos: Array<PoMultiselectOption> = [
    { value: 'SIGATMK', label: 'Call Center'},
    { value: 'SIGACOM', label: 'Compras'},
    { value: 'SIGACTB', label: 'Contabilidade'},
    { value: 'SIGAEST', label: 'Estoque'},
    { value: 'SIGAFAT', label: 'Faturamento'},
    { value: 'SIGAFIN', label: 'Financeiro'},
    { value: 'SIGAFIS', label: 'Fiscal'},
    { value: 'SIGAPON', label: 'Ponto Eletrônico'},
    { value: 'SIGAGPE', label: 'RH'},
    { value: 'OUTROS' , label: 'Outros'},
  ];

  modulo = '';

  constructor(
      private http: HttpService,
      private poNotifica: PoNotificationService,
      private rota: Router,
    ) { }

  async ngOnInit()  {
    await this.getContatos();
  }

  antesdeupar(event): void {

  }

  uparAnexos(chave): void {

    for (let index = 0; index < this.project.length; index++) {
      const element = this.project[index];
      const file = element.rawFile;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
          const arquivo = element.name;
          const body = {
            base64: reader.result, extension: element.extension, 
            name: arquivo, diretorio: 'tickets', chave: chave};
          this.http.post('/nyxupload/', body).subscribe(
            data => {
              if (index === this.project.length) {
                this.rota.navigate(['/'])
              }
            }
          );
      };      
    }

    this.upload.sendFiles();
    this.upload.clear();
  }


  salvarTicket(): void {
    this.carregando = true;
    this.http.post('/suporte/ticket?', this.passwordForm.value).subscribe(
      data => {
        this.carregando = false;
        const ret: any = data;
        this.poNotifica.success(ret.message);
        this.passwordForm.resetForm();       
        this.uparAnexos(ret.ticket);
        this.descricao = '';
        if (this.project.length === 0) {
          this.rota.navigate(['/'])
        }
      },
      error => {
        this.carregando = false;
        const ret: any = error;
        this.poNotifica.error(error.message);
      }
    );

  }


  async getContatos() {

    return new Promise((resolve, reject) => {

      this.http.get('/suporte/usuarios?').subscribe(
        data => {
          this.contatos = [];
          for (const contato of data.items ) {
            this.contatos.push({label: contato.nome.trim(), value: contato.id});
          }
          resolve(true);
        },
        error => {
          this.carregando = false;
          const ret: any = error;
          this.poNotifica.error(error.message);
          reject(false);
        })
      });  

  }

}
