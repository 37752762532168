<div *ngIf="tabgd" class="center">
    
    <iframe #iframe class="dash" frameBorder="0"        
        title="">
    </iframe>
    
</div>

<div *ngIf="!tabgd" class="center top2 ">
    
    <div class="po-font-title">
        Você ainda não possui acesso ao dashboard de tickets. 
    </div>
    <div class="po-font-subtitle">
        Abra um ticket solicitando o acesso.
    </div>
    
</div>

