import { PoModalAction, PoModalComponent, PoNotificationService } from '@po-ui/ng-components';
import { HttpService } from 'src/app/services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-detalhe-ticket',
  templateUrl: './detalhe-ticket.component.html',
  styleUrls: ['./detalhe-ticket.component.css']
})
export class DetalheTicketComponent implements OnInit {

  @ViewChild('poModalAnexo') poModalAnexo: PoModalComponent;
  @ViewChild('poModalFinaliza') poModalFinaliza: PoModalComponent;

  constructor(
    private rota: ActivatedRoute, 
    private route: Router, 
    private http: HttpService,
    private notifica: PoNotificationService
    ) { }

  ticket;
  corstatus = '';
  comentario = '';
  carregando;
  contatos = []
  contatossel = [];
  detalheticket = {
    anexos: [],
    assunto: "-",
    atualizado: "-",
    inicio: "-",
    pathanexos: "",
    status: "-",
    ticket: "-",
    descricao: "",
    interno: false,
  }

  sequencias = [];

  url = environment.urlHttp;

  close: PoModalAction = {
    action: () => {
      this.poModalFinaliza.close();
    },
    label: 'Cancelar',
  };

  confirm: PoModalAction = {
    action: () => {
      this.finalizarTicket();
    },
    label: 'Confirmar'
  };

  async ngOnInit() {
    
    await this.getContatos();
    
    this.rota.paramMap.subscribe(parameters => {
      this.ticket = parameters.get('ticket');
      this.getSequencias();      
    });
  }

  getSequencias(): void {
    
    this.carregando = true;
  
    this.http.get('/suporte/sequencia?ticket=' + this.ticket).subscribe(
      data => {
        const ret: any = data;
        
        if (ret.code == '401') {
          this.carregando = false;
          this.notifica.error('Erro ao tentar listar sequências. ' + ret.message)
        } else {
        
          this.sequencias = ret.items;
          this.detalheticket = ret.ticket;
          this.contatossel = ret.ticket.contatos.map(el => el.value)
          
          if (this.detalheticket.status === 'Novo') {
            this.corstatus =  'color-10';
          } 
          if (this.detalheticket.status === 'Em Atendimento') {
            this.corstatus =  'color-02';
          }
          if (this.detalheticket.status === 'Aguardando Cliente') {
            this.corstatus =  'color-07';
          }
          if (this.detalheticket.status === 'Finalizado') {
            this.corstatus =  'color-08';
          }
        }
        this.carregando = false;
      },
      error => {
        this.carregando = false;
        this.notifica.error('Erro ao tentar listar sequências.')
      }
    )
  }


  novoAnexo(): void {
    this.poModalAnexo.open();
  }

  salvarSequencia(): void {

    const body = {
        ticket: this.ticket,
        descricao: this.comentario,
        interno: false
    }

    this.http.post('/suporte/sequencia?', body).subscribe(
      data => {
        this.getSequencias();
        this.comentario = '';
      },
      error => {
        this.notifica.error('Erro ao tentar gravar nova sequência.')
      }
    )

  }

  contirmafinalizarTicket() {
    this.poModalFinaliza.open();
  }

  finalizarTicket() {

    const body = {
      ticket: this.ticket,
      descricao: 'Encerramento de Ticket pelo usuário.',
      interno: false,
      finaliza: true,

    }

    this.http.post('/suporte/sequencia?', body).subscribe(
      data => {
        const ret: any = data;
        console.log(ret);
        if (ret.code === '200') {
          this.route.navigate(['/']);
        } else {
          this.notifica.error('Erro ao tentar Finalizar Ticket.')
        }
      },
      error => {
        this.notifica.error('Erro ao tentar Finalizar Ticket.')
      },
      () => {
        this.poModalFinaliza.close();
      }
    )

  }

  async getContatos() {

    return new Promise((resolve, reject) => {

      this.http.get('/suporte/usuarios?').subscribe(
        data => {
          this.contatos = [];
          for (const contato of data.items ) {
            this.contatos.push({label: contato.nome.trim(), value: contato.id});
          }
          resolve(true);
        },
        error => {
          this.carregando = false;
          const ret: any = error;
          this.notifica.error(error.message);
          reject(false);
        })
      });  

  }  

  atualizarContatos(contatos) {
    const body = {
      contatos
    }
    this.atualizaTicket(body)      
  }

  atualizaTicket(body) {

    this.http.post('/suporte/atualizar?ticket=' + this.ticket, body).subscribe(
      data => {
        const ret: any = data;        
        if ( ret.code !== '200') {
          this.notifica.error('Erro ao tentar atualizar Ticket.')
        }
      },
      error => {
        this.notifica.error('Erro ao tentar atualizar Ticket')
      },
    )  
  }

  
}
 