<po-page-default class="page">
    <div [style]="colunasestilo" class="slider top">        
        <po-widget     
        *ngFor="let analista of analistas; let idAnalista=index"        
        class="top widget po-xl-12 po-lg-12 po-md-12 po-sm-12"
        p-title="{{analista.analista.nome + ' - ' + analista.redmines.length }}">                                  
        <div id="{{idAnalista}}" class="lista" cdkDropList (cdkDropListDropped)="drop($event,idAnalista)">
            <div class="boxtarefa po-xl-12 po-lg-12 po-md-12 po-sm-12" [cdkDragDisabled]="tpacesso!=='F'" *ngFor="let item of analista.redmines; let i=index" cdkDrag>                
                <po-container  p-no-padding="true" p-no-border="true" class="box po-xl-12 po-lg-12 po-md-12 po-sm-12">                       
                    <div class="po-xl-12 po-lg-12 po-md-12 po-sm-12">
                        <po-tag p-color="color-01" p-value="{{item.issue}}"> </po-tag>                       
                        <div class=".po-font-text-small">
                            <po-switch                                 
                                [ngClass]="{'switchoff': iduser !== analista.analista.id}"                                
                                [(ngModel)]="item.status"
                                (p-change)="status($event,item,idAnalista)"
                                p-label-off="Aguardando"
                                p-label-on="Fazendo"    
                                name="status"> 
                            </po-switch>
                        </div>
                        <po-divider></po-divider>
                        <div (click)="detalhetarefa(item)">
                            <div class="ordem po-font-text-small-bold">
                                {{item.titulo}}
                            </div>                        
                            <div class="po-font-text-small">
                                {{item.descricao.substring(0,150) + ((item.descricao.length > 150) ? '...' : '' )}}                       
                            </div>     
                        </div>
                    </div>
                </po-container>
            </div>
        </div>                                    

        </po-widget>
    </div>

</po-page-default>