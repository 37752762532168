import { PoNotificationService } from '@po-ui/ng-components';
import { HttpService } from './../services/http.service';
import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { environment } from 'src/environments/environment';
import { element } from 'protractor';
// import { CONNREFUSED } from 'dns';

@Component({
  selector: 'app-painel-redmine',
  templateUrl: './painel-redmine.component.html',
  styleUrls: ['./painel-redmine.component.css']
})
export class PainelRedmineComponent implements OnInit {

  constructor(private http: HttpService,
              private notificacao: PoNotificationService
    ) { }

  aguardando = [];
  analistasAux = []
  analistas = []
  iduser;
  tpacesso;
  colunasestilo = 'display: grid;grid-template-columns: repeat(11, 320px);'

  tarefas = ['tarefa1', 'tarefa2', 'tarefa3', 'tarefa4', 'tarefa5']

  async ngOnInit()  {    
    let sessao = JSON.parse(localStorage.getItem('PO_USER_LOGIN'));
    this.iduser = sessao.id;
    this.tpacesso = sessao.tpacesso;
    await this.getRedmines();
    this.colunasestilo = 'display: grid;grid-template-columns: repeat('+this.analistas.length+', 320px);'
  }
  
  drop(event: CdkDragDrop<string[]>, indexAnalista) {
        
    if (event.previousIndex === event.currentIndex) {
      return
    }
        
    moveItemInArray(this.analistas[indexAnalista].redmines, event.previousIndex, event.currentIndex);
    
    this.atualizarSequencia(event, indexAnalista)
  }


  async atualizarSequencia(event, indexAnalista) {

    let inicio
    let fim
    let tickets = []

    if (event.previousIndex > event.currentIndex) {
      inicio = event.currentIndex;
      fim = event.previousIndex;
    } else {
      inicio = event.previousIndex;
      fim = event.currentIndex;
    }

    while (inicio <= fim) {
            
      let ticket = this.analistas[indexAnalista].redmines[inicio].issue;

      tickets.push({ticket: ticket, sequencia: inicio})
      inicio++      
    }

    if (tickets.length > 0) {
      let idanalista = this.analistas[indexAnalista].analista.id;
      let ret = await this.atualizarRedmine(idanalista, tickets);
      if (!ret) {
        this.notificacao.error('Erro ao atualizar sequencias!')
        this.getRedmines();
      }
    }
  }

  async atualizarRedmine(id, tickets) {

    let body =  {
      tickets: tickets
    }  
    
    return new Promise((resolve, reject) => {    
            
      //redmines/ticket?analista=754&ticket=21953
      this.http.post('/redmines/tickets?analista='+id, body).subscribe(      
        data => {
        console.log(data)
        resolve(true);
      },
      error => {
        console.log(error);
        reject(false);
      });
    });

  }

  async getRedmines() {

    return new Promise((resolve, reject) => {  

    this.http.get('/redmines/tickets?analista='+ this.iduser + '&'  ).subscribe(
      data => {
        let ret = data;
        this.analistas = ret.items;               
        console.log(this.analistas);
        resolve(true)
      },
      error => {
        reject(false);
      })    
   });
  }


 detalhetarefa(item){
   console.log(item);
   window.open(environment.urlRedmine + '/issues/' + item.issue, '_blank');
 }

 status(event, item, indexAnalista ) {
  
  let body = {
    status: event ? 'S' : 'N'
  }
  
  let idanalista = this.analistas[indexAnalista].analista.id;

  this.http.post('/redmines/status?analista='+idanalista+'&ticket='+item.issue, body).subscribe(      
    data => {
    console.log(data)
  },
  error => {
    console.log(error);
  });
 }

}
