  <po-navbar        
    *ngIf="mostrarmenu" 
    [p-logo]="logo"
    p-shadow="true"
    [p-items]="items"
    [p-icon-actions]="icones">          
  </po-navbar>

  <!-- <po-menu  *ngIf="mostrarmenu" [p-menus]="menus" p-filter="true"></po-menu> -->
  
  
  <router-outlet></router-outlet> 