import { DashboardComponent } from './dashboard/dashboard.component';
import { AtualizarComponent } from './atualizar/atualizar.component';
import { PainelRedmineComponent } from './painel-redmine/painel-redmine.component';
import { DetalheTicketComponent } from './detalhe-ticket/detalhe-ticket.component';
import { NovoTicketComponent } from './novo-ticket/novo-ticket.component';
import { environment } from './../environments/environment';
import { AuthGuard } from './services/auth.guard';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { NovoUsuarioComponent } from './novo-usuario/novo-usuario.component';
import { RedefinirSenhaComponent } from './redefinir-senha/redefinir-senha.component'

const routes: Routes = [

  {path: '', component: HomeComponent, canActivate: [AuthGuard] },
  {path: 'ticket', component: NovoTicketComponent, canActivate: [AuthGuard] },
  {path: 'consulta/:ticket', component: DetalheTicketComponent, canActivate: [AuthGuard] },
  {path: 'painel-redmine', component: PainelRedmineComponent, canActivate: [AuthGuard]},
  {path: 'atualizar', component: AtualizarComponent, canActivate: [AuthGuard]},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'novousuario', component: NovoUsuarioComponent},
  {
    path: 'login', component: LoginComponent, data: {
    serviceApi: environment.urlApi + '/suporte/login',
    environment: 'USER FUNCTION',
    recovery: {
      
    }
    }
  },
  {path: 'redefinir-senha', component: RedefinirSenhaComponent },
];

@NgModule({  
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
